import { useApolloClient } from "@apollo/client";
import {
  Banner,
  Icon,
  Layout,
  LegacyCard,
  LegacyStack,
  Link,
  Page,
  ResourceItem,
  ResourceList,
  Spinner,
  Text,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { DeliveryProfileFragmentFragment } from "@smartrr/shared/shopifyGraphQL/api";
import {
  mutationShopifyDeliveryProfileRemove,
  paginatedQueryShopifyDeliveryProfiles,
  queryShopDeliverySettings,
} from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import React, { useEffect, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
export function AdminDeliveryProfilesRoute(): JSX.Element {
  const shopifyDomain = useMyShopifyDomainSelector();
  const apolloClient = useApolloClient();
  const { addToast } = useToast();

  const [loadingDeliverySettings, setLoadingDeliverySettings] = useState(false);
  const [isLegacy, setIsLegacy] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [deliveryProfiles, setDeliveryProfiles] = useState<DeliveryProfileFragmentFragment[]>([]);

  const fetchProfiles = async () => {
    setLoadingProfiles(true);
    const res = await paginatedQueryShopifyDeliveryProfiles(apolloClient);
    const subscriptionProfiles = res.filter(profile => !!profile.sellingPlanGroups.edges.length);
    setDeliveryProfiles(subscriptionProfiles);
    setLoadingProfiles(false);
  };

  const fetchDeliverySettings = async () => {
    setLoadingDeliverySettings(true);
    const res = await queryShopDeliverySettings(apolloClient);
    if (res.type === "success" && !!res.body.data.deliverySettings?.legacyModeProfiles) {
      setIsLegacy(true);
    }
    setLoadingDeliverySettings(false);
  };

  const removeProfile = async (id: string) => {
    try {
      await mutationShopifyDeliveryProfileRemove(id, apolloClient);
      setDeliveryProfiles(profiles => profiles.filter(p => p.id !== id));
      addToast(`Profile ${shopifyGidToNumber(id)} removed`);
    } catch (error) {
      addToast(error.message);
    }
  };

  useEffect(() => {
    fetchDeliverySettings();
    fetchProfiles();
  }, []);

  return (
    <React.Fragment>
      <Page
        title="Delivery settings"
        primaryAction={{
          content: "Create subscription delivery profile",
          onAction: () => navigateWithShopInQuery(`${adminConfigRoutePrefix}/delivery-profiles/create`),
          loading: loadingDeliverySettings,
          disabled: isLegacy,
        }}
      >
        <Layout>
          <Layout.Section>
            {!!isLegacy && (
              <Box mb={1}>
                <Banner status="warning" title="Legacy compatibility mode is enabled">
                  <p>
                    Smartrr has detected that legacy compatibility mode is enabled for your shipping profiles. No
                    new profiles can be created while this setting is active. For more information and
                    instructions on how to enable multiple shipping profiles, see{" "}
                    <Link
                      external
                      url="https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/convert-to-shipping-profile"
                    >
                      Activate multi-origin shipping
                    </Link>{" "}
                    on Shopify.
                  </p>
                </Banner>
              </Box>
            )}
            <LegacyCard title="Subscription delivery profiles">
              <LegacyCard.Section>
                <Text variant="bodyMd" as="span" color="subdued">
                  General delivery profiles or profiles for non-subscription products can be configured in{" "}
                  <Link external url={`https://${shopifyDomain}/admin/settings/shipping`}>
                    Shopify
                  </Link>
                  .
                </Text>
                <div style={{ marginTop: "1rem" }}>
                  {loadingProfiles ? (
                    <LegacyStack alignment="center" distribution="center">
                      <Spinner />
                    </LegacyStack>
                  ) : (
                    <ResourceList
                      items={deliveryProfiles}
                      renderItem={deliveryProfile => (
                        <ResourceItem
                          id={deliveryProfile.id}
                          onClick={() =>
                            navigateWithShopInQuery(
                              `${adminConfigRoutePrefix}/delivery-profiles/${shopifyGidToNumber(
                                deliveryProfile.id
                              )}`
                            )
                          }
                        >
                          <Box py={0.6} justifyContent="space-between">
                            <Text variant="bodyMd" as="span" fontWeight="semibold">
                              {deliveryProfile.name}
                            </Text>
                            <div
                              onClick={e => {
                                e.stopPropagation();
                                removeProfile(deliveryProfile.id);
                              }}
                            >
                              <Icon source={DeleteMajor} />
                            </div>
                          </Box>
                        </ResourceItem>
                      )}
                    />
                  )}
                </div>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </Page>
    </React.Fragment>
  );
}
